import {
  Business,
  BusinessOutlined,
  Group,
  GroupsOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { Box, MenuList, Typography } from "@mui/material";
import Avatar from "@src/lib/Avatar";
import CornerMenu from "@src/lib/CornerMenu";
import MenuLayout from "@src/lib/MenuLayout";
import { MenuLink, MenuPaper } from "@src/lib/NavigationMenus";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MenuTab } from "./routes";

type Props = {
  selectedTab: MenuTab;
  children: React.ReactNode;
};

function SystemMenu({ selectedTab }: { selectedTab: MenuTab }) {
  const user = useCurrentStaff();
  const { system, selectFacility } = useGuaranteedFacilityContext();
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <MenuPaper>
      <CornerMenu
        onSelectFacility={(f) => {
          selectFacility(f);
          navigate("/");
        }}
      >
        <Box
          m={2}
          mr={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Avatar
            fallback={system.code}
            showFull
            style={{ fontSize: "inherit" }}
          />
          <Box ml={1} sx={{ flex: 1, minWidth: 0 }}>
            <Typography
              variant="h3"
              color="common.white"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              component="p"
            >
              {system.code}
            </Typography>
            {/* dark.text.secondary */}
            <Typography variant="body1" color="rgba(255,255,255,0.70)">
              {user.fullName}
            </Typography>
          </Box>
          <KeyboardArrowDown sx={{ color: "common.white" }} fontSize="small" />
        </Box>
      </CornerMenu>

      <MenuList>
        <MenuLink
          key="system-facilities"
          icon={<BusinessOutlined />}
          selectedIcon={<Business />}
          path="/system/facilities"
          tab="system-facilities"
          selectedTab={selectedTab}
        >
          {t("Facilities")}
        </MenuLink>
        <MenuLink
          key="system-staff"
          icon={<GroupsOutlined />}
          selectedIcon={<Group />}
          path="/system/staff"
          tab="system-staff"
          selectedTab={selectedTab}
        >
          {t("Staff")}
        </MenuLink>
      </MenuList>
    </MenuPaper>
  );
}

export default function SystemLayout({ selectedTab, children }: Props) {
  return (
    <MenuLayout menu={<SystemMenu selectedTab={selectedTab} />}>
      {children}
    </MenuLayout>
  );
}
