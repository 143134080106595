import { Media } from "@ameelio/ui";
import { onlyMobile } from "@ameelio/ui/src/helpers/responsiveHelpers";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import React, { ReactElement } from "react";

export default function Tile({
  title,
  value,
  onClick,
  icon,
}: {
  title: string;
  value: number;
  icon: ReactElement;
  onClick?: () => void;
}) {
  return (
    <Card
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <CardActionArea onClick={onClick} sx={{ borderRadius: 2, height: 1 }}>
        <CardContent
          sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            height: 1,
          }}
        >
          <Typography
            marginY={2}
            sx={{
              fontSize: "4em",
              textDecoration: "none",
              display: "block",
              textAlign: "center",
              color: "primary.main",
            }}
          >
            {value}
          </Typography>
          <Box display="flex" justifyContent="center" sx={{ flexGrow: 1 }}>
            <Media
              image={icon}
              gap={1}
              styleOverrides={{
                alignItems: "flex-start",
              }}
            >
              <Typography variant="body1" fontWeight="normal">
                {title}
              </Typography>
            </Media>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function TileSection({
  title,
  children,
  tileCount = 4,
}: {
  title: string;
  children: React.ReactNode;
  tileCount?: number;
}) {
  const isMobile = onlyMobile(useMediaQuery);
  return (
    <Box>
      <Typography variant="h2" mb={1}>
        {title}
      </Typography>
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: "white",
          overflow: "auto",
          py: 1.5,
          px: 2,
        }}
      >
        {isMobile ? (
          <Stack alignItems="center">{children}</Stack>
        ) : (
          <ResponsiveColumns
            sx={{
              gridTemplateColumns: `repeat(${tileCount}, 1fr)`,
            }}
          >
            {children}
          </ResponsiveColumns>
        )}
      </Box>
    </Box>
  );
}
