import { Button, Dialog, SelectInput, TextInput } from "@ameelio/ui";
import { Box, DialogActions, DialogContent, Typography } from "@mui/material";
import { isRequired, isRequiredString } from "@src/lib/validate";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CALL_ALERTS, VOICE_CALL_ALERTS } from "../constants";

type Props = {
  type: "video" | "voice";
  onSend: (message: string) => void;
  onClose: () => void;
};

type FormData = {
  alert: string;
  customAlert: string;
};

export default function SendAlertDialog({ type, onSend, onClose }: Props) {
  const { t } = useTranslation();
  const alerts = type === "video" ? CALL_ALERTS : VOICE_CALL_ALERTS;

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      alert: "",
      customAlert: "",
    },
  });

  const alertTitle = watch("alert");
  const alertInfo = alertTitle
    ? alerts.find((a) => a.key === alertTitle)
    : null;
  const isCustomAlert = alertTitle === "custom";

  return (
    <Dialog fullWidth title={t("Send alert")} onClose={onClose}>
      <DialogContent>
        <SelectInput
          control={control}
          name="alert"
          size="small"
          aria-label={t("Alert type")}
          style={{ width: "auto" }}
          label={t("Alert type")}
          rules={isRequired(t("Please select an alert type."))}
          items={alerts.map((a) => ({
            value: a.key,
            name: a.title,
          }))}
        />
        {alertInfo &&
          (isCustomAlert ? (
            <Box mt={4}>
              <Typography variant="h3" color="text.primary" sx={{ mb: 2 }}>
                {t("Please type your message")}
              </Typography>
              <TextInput
                control={control}
                name="customAlert"
                fullWidth
                label={t("Message")}
                rules={isRequiredString(t("Please enter a message."))}
              />
            </Box>
          ) : (
            <Box mt={4}>
              <Typography variant="h3" color="text.primary" sx={{ mb: 1 }}>
                {t("Send the following alert message?")}
              </Typography>
              <Typography variant="body1" color="text.primary">
                {alertInfo.body}
              </Typography>
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit((data) => {
            const message =
              (isCustomAlert ? data.customAlert : alertInfo?.body) || "";
            if (message) {
              onSend(message);
            }
            onClose();
          })}
        >
          {t("Send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
