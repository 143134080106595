import { ApolloProvider } from "@apollo/client";
import getAuthenticatedClient from "@src/api/client";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";

type Props = {
  children: React.ReactNode;
};

export default function AuthenticatedClient({ children }: Props) {
  const navigate = useNavigate();
  // `navigate` updates on path changes so that it can perform
  // relative path routing. we don't need that, and we don't
  // want to re-generate our apolloClient on each path change.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = useCallback(() => navigate("/logout"), []);
  const apolloClient = useMemo(() => getAuthenticatedClient(logout), [logout]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
