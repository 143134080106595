import { useQuery } from "@apollo/client";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Meeting, MeetingStatus, MeetingType } from "@src/api/graphql";
import featureEnabled from "@src/lib/featureEnabled";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { SectionTitle } from "@src/lib/typography";
import useMountedPolling from "@src/lib/useMountedPolling";
import { endOfDay, startOfDay } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import Header from "../../lib/Header";
import {
  GetUpcomingMeetingsDocument,
  GetUpcomingMeetingsQuery,
} from "../UpcomingMeetingsPage/GetUpcomingMeetings.generated";
import DailyScheduleCard from "./DailyScheduleCard";
import DailySchedulePicker from "./DailySchedulePicker";
import useSortedGroupedMeetings from "./useSortedGroupedMeetings";

const REFRESH_INTERVAL = 3 * 60000; // 3 minute
const MEETINGS_LIMIT = 100;

export type ScheduleMeeting = Pick<
  Meeting,
  "id" | "status" | "meetingType" | "interval"
> & {
  kiosk?: { name: string } | null;
  inmates: Array<{ fullName: string; inmateIdentification: string }>;
  visitors: Array<{ fullName: string }>;
};

export type GroupedMeetings = Record<string, ScheduleMeeting[]>;

export default function DailySchedulePage() {
  const { t } = useTranslation();
  const { facility: selectedFacility } = useGuaranteedFacilityContext();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const handleApolloError = useApolloErrorHandler();

  const { loading, data, startPolling, stopPolling } =
    useQuery<GetUpcomingMeetingsQuery>(GetUpcomingMeetingsDocument, {
      variables: {
        id: selectedFacility.id,
        limit: MEETINGS_LIMIT,
        scheduledStartAfter: selectedDate
          ? startOfDay(selectedDate).getTime()
          : null,
        scheduledStartBefore: selectedDate
          ? endOfDay(selectedDate).getTime()
          : null,
        meetingStatus: [MeetingStatus.Scheduled, MeetingStatus.Live],
        meetingType: [
          MeetingType.VideoCall,
          MeetingType.InPersonVisit,
          MeetingType.Webinar,
        ],
      },
      onError: handleApolloError,
      skip: !selectedDate,
    });

  useMountedPolling(REFRESH_INTERVAL, startPolling, stopPolling);

  const sortedGroupedMeetings = useSortedGroupedMeetings(data);

  if (selectedFacility.country !== "PK" && !featureEnabled("DAILY_SCHEDULE")) {
    return <Navigate to="/meetings/upcoming" />;
  }

  return (
    <Box>
      <Header title={t("Daily schedule")} subtitle={t("View today's meetings")}>
        <Box display="flex" alignItems="center">
          <DailySchedulePicker
            selectedDate={selectedDate}
            onDateChange={setSelectedDate}
          />
        </Box>
      </Header>
      <Box p={3}>
        <Stack gap={2}>
          {loading && <CircularProgress />}
          {!loading && data?.facility.meetings.edges.length === 0 && (
            <Alert severity="info">
              {t("No meetings scheduled for this day")}
            </Alert>
          )}
          {!loading &&
            sortedGroupedMeetings.map(({ timeSlot, meetings }) => (
              <Box key={timeSlot} mb={4}>
                <SectionTitle>{timeSlot}</SectionTitle>
                <Grid container spacing={2} gap={2} mt={1}>
                  {meetings?.map((meeting) => (
                    <Grid item key={meeting.id} xs={12} sm={6} md={4} lg={3}>
                      <DailyScheduleCard meeting={meeting} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          {!loading && data?.facility.meetings.edges.length !== 0 && (
            <Typography variant="body2">
              {t("Total meetings: {{count}}", {
                count: data?.facility.meetings.edges.length || 0,
              })}
            </Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
