import { Tooltip } from "@ameelio/ui";
import { ReactElement } from "react";

type Props = {
  active: boolean;
  title: string;
  children: ReactElement;
};

export default function ConditionalTooltip({ active, title, children }: Props) {
  return active ? (
    <Tooltip title={title}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );
}
