import BusinessIcon from "@mui/icons-material/Business";
import { Organization } from "@src/api/graphql";
import { useTranslation } from "react-i18next";
import Avatar from "./Avatar";

type Props = {
  organization: Pick<Organization, "name" | "logoImageUrl">;
  size?: number;
  fallbackIconSize?: "small" | "medium" | "large";
  styleOverrides?: React.CSSProperties;
};

export default function OrganizationLogo({
  organization,
  size,
  fallbackIconSize,
  styleOverrides,
}: Props) {
  const { t } = useTranslation();
  return (
    <Avatar
      showFull
      src={organization.logoImageUrl || undefined}
      fallback={t("Logo for {{name}}", {
        name: organization.name,
      })}
      fallbackIcon={<BusinessIcon fontSize={fallbackIconSize || "medium"} />}
      style={{
        maxWidth: size || 40,
        maxHeight: size || 40,
        objectFit: "contain",
        ...(styleOverrides ? styleOverrides : {}),
      }}
    />
  );
}
