import { MeetingType } from "@src/api/graphql";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useLiveCalls from "@src/lib/useLiveCalls";
import DashboardNew from "@src/pages/DashboardPage/DashboardNew";
import React from "react";

const DashboardPage: React.FC = () => {
  const { facility } = useGuaranteedFacilityContext();

  const meetingTypes = [
    MeetingType.VideoCall,
    MeetingType.VoiceCall,
    MeetingType.InPersonVisit,
    MeetingType.Webinar,
  ];

  const liveMeetings = useLiveCalls({
    facility,
    meetingTypes,
  });

  return <DashboardNew liveMeetings={liveMeetings} facility={facility} />;
};

export default DashboardPage;
