import { Box, Link, Stack, Typography } from "@mui/material";
import { Visitor } from "@src/api/graphql";
import { useAppNavigate } from "@src/lib/useAppNavigate";

export type MeetingVisitor = Pick<Visitor, "id" | "fullName" | "dateOfBirth">;

interface Props {
  registeredGuests: MeetingVisitor[];
  unregisteredGuests: string[];
}

/**
 * GuestList displays only the registered and unregistered
 * guests, excluding the primary visitor.
 */
export default function GuestList({
  registeredGuests,
  unregisteredGuests,
}: Props) {
  const navigate = useAppNavigate();
  return (
    <Stack>
      {registeredGuests.map((guest) => (
        <Box sx={{ whiteSpace: "nowrap" }} key={guest.id}>
          <Link onClick={() => navigate(`/visitors/${guest.id}`)}>
            {guest.fullName}
          </Link>
        </Box>
      ))}
      {unregisteredGuests.map((guest, idx) => (
        <Typography variant="body1" key={idx}>
          {guest}
        </Typography>
      ))}
    </Stack>
  );
}
