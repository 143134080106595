import {
  AspectRatio,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
  TextInput,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Connection, SystemUser, Visitor } from "@src/api/graphql";
import { GetVisitorPhotosDocument } from "@src/graphql/GetVisitorPhotos.generated";
import { CardSkeleton } from "@src/lib/closet";
import DetailsStack, { DetailsStackItem } from "@src/lib/DetailsStack";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import identityDocumentTypeLabel from "@src/lib/identityDocumentTypeLabel";
import IdentityImage from "@src/lib/IdentityImage";
import { ImageNumber } from "@src/lib/imageLabelForDocumentType";
import { getDisplayBirthday } from "@src/lib/Time";
import { isRequired, isRequiredString } from "@src/lib/validate";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Props = {
  connection: Pick<Connection, "id"> & {
    visitor: Pick<Visitor, "id" | "fullName" | "dateOfBirth"> & {
      systemRelationship?: Pick<SystemUser, "id"> | null;
    };
  };
  onCancel: () => void;
  onReject: (reason: string) => void;
  onApprove: () => void;
};

type FormData = {
  decision: "" | "approve" | "reject";
  reason: string;
  otherReason: string;
};

export default function IdentityReviewModal({
  connection,
  onCancel,
  onReject,
  onApprove,
}: Props) {
  const { t } = useTranslation();

  const OTHER = t("Other");
  const OTHER_PREAMBLE = t(
    "Unfortunately, we cannot approve this government ID because:"
  );
  const REJECTION_REASONS = [
    t(
      "The pictures of your government ID are blurry. Please retake clear and readable photos."
    ),
    t("Your government ID does not match your provided name or date of birth."),
    t(
      "Your government ID has expired. Please renew your ID before resubmitting."
    ),
    t(
      "The picture of you with your government ID must be clear with a readable ID."
    ),
    OTHER,
  ];

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      decision: "",
      reason: "",
      otherReason: "",
    },
  });

  const doSubmit = async (data: FormData) => {
    if (data.decision === "reject") {
      await onReject(
        data.reason === OTHER
          ? `${OTHER_PREAMBLE} ${data.otherReason.trim()}`
          : data.reason
      );
    } else {
      await onApprove();
    }
  };

  const decision = watch("decision");
  const reason = watch("reason");
  const { visitor } = connection;
  const handleApolloError = useApolloErrorHandler();

  // We always want to fetch the image URLs when the component mounts
  // so that they won't have expired already
  const { data: photosData } = useQuery(GetVisitorPhotosDocument, {
    variables: { visitorId: visitor.id },
    onError: handleApolloError,
    fetchPolicy: "no-cache", // Done so that the query result is not stored in the cache
  });
  const identity = photosData?.visitor.identity;
  const loading = !identity;

  const imageNumbers = [
    ImageNumber.ImageOne,
    ImageNumber.ImageTwo,
    ImageNumber.ImageThree,
  ];

  return (
    <Dialog title={t("Review ID")} fullWidth maxWidth="sm" onClose={onCancel}>
      <DialogContent>
        <DetailsStack>
          <DetailsStackItem
            label={t("Full name") + ":"}
            value={visitor.fullName}
            typographyVariant="body2"
          />
          <DetailsStackItem
            label={t("Date of birth") + ":"}
            value={
              visitor.dateOfBirth
                ? getDisplayBirthday(visitor.dateOfBirth)
                : t("Unknown")
            }
            typographyVariant="body2"
          />
          <DetailsStackItem
            label={t("ID type") + ":"}
            value={
              identity ? identityDocumentTypeLabel(identity.documentType) : "–"
            }
            typographyVariant="body2"
          />
        </DetailsStack>
        <Stack direction="row" spacing={1.5} mt={1.5}>
          {loading
            ? imageNumbers.map((num) => (
                <Box key={num} flex={1}>
                  <AspectRatio key={num} ratio={1}>
                    <CardSkeleton height="100%" />
                  </AspectRatio>
                </Box>
              ))
            : imageNumbers.map((num) => (
                <Box key={num} flex={1}>
                  <IdentityImage
                    key={num}
                    identity={identity}
                    imageNumber={num}
                  />
                </Box>
              ))}
        </Stack>
        <form onSubmit={handleSubmit(doSubmit)}>
          <Box sx={{ mt: 2, px: 2, py: 1.5, backgroundColor: grey[50] }}>
            <Typography variant="subtitle2" color="text.primary">
              {t("Approve or reject this ID?")}
            </Typography>
            <Controller
              control={control}
              name="decision"
              rules={isRequired(t("Please make a decision."))}
              render={({ field }) => (
                <RadioGroup {...field} sx={{ mt: 1 }}>
                  <FormControlLabel
                    value="approve"
                    control={<Radio />}
                    label={
                      <Typography variant="body2" color="text.primary">
                        {t("Approve")}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="reject"
                    control={<Radio />}
                    label={
                      <Typography variant="body2" color="text.primary">
                        {t("Reject")}
                      </Typography>
                    }
                  />
                </RadioGroup>
              )}
            />
            {decision === "reject" && (
              <Box mt={2} style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="subtitle2" color="text.primary">
                  {t("Why is this ID being rejected?")}
                </Typography>
                <Controller
                  control={control}
                  name="reason"
                  rules={isRequired(t("Please select a reason."))}
                  render={({ field }) => (
                    <RadioGroup {...field} sx={{ pt: 1 }}>
                      {REJECTION_REASONS.map((reason) => (
                        <FormControlLabel
                          key={reason}
                          control={<Radio />}
                          value={reason}
                          label={
                            <Typography variant="body2" color="text.primary">
                              {reason}
                            </Typography>
                          }
                          sx={{ my: 1 }}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
                {reason === OTHER && (
                  <TextInput
                    control={control}
                    name="otherReason"
                    label={OTHER_PREAMBLE}
                    rules={isRequiredString(t("Please provide a reason."))}
                    multiline
                    rows={2}
                    sx={{ ml: 4, flex: 1 }}
                  />
                )}
              </Box>
            )}
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <SubmitButton
          disabled={!isValid}
          submitting={isSubmitting}
          onClick={handleSubmit(doSubmit)}
        >
          {t("Confirm")}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
