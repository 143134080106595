import { useQuery } from "@apollo/client";
import Alert from "@src/lib/Alert";
import Header from "@src/lib/Header";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import SelectableFieldStringFilter from "@src/lib/SelectableFieldStringFilter";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { GetFacilityInmatesDocument } from "./GetFacilityInmates.generated";
import InmateTable from "./InmateTable";

const LIMIT = 100;

const Filters = ["fullName", "inmateIdentification"] as const;
type TFilter = typeof Filters[number];

function isFilter(value: unknown): value is TFilter {
  return typeof value === "string" && Filters.includes(value as TFilter);
}

const { history } = window;

export default function InmatesPage() {
  const { facility } = useGuaranteedFacilityContext();
  const location = useLocation();
  const { t } = useTranslation();
  const refetchTimeout = useRef<NodeJS.Timeout | null>(null);
  const hasMounted = useRef(false);

  const { data, loading, error, refetch } = useQuery(
    GetFacilityInmatesDocument,
    {
      notifyOnNetworkStatusChange: true, // So that refetch triggers loading: true
      variables: {
        facilityId: facility.id,
        limit: LIMIT,
        // search filters are restored from page state for back navigation
        fullName: location?.state?.fullName ? location.state.fullName : "",
        inmateIdentification: location?.state?.inmateIdentification
          ? location.state.inmateIdentification
          : "",
      },
    }
  );
  if (error) throw error;

  const FILTER_TO_LABEL_MAP: Record<TFilter, string> = {
    fullName: t("Name"),
    inmateIdentification: t("Unique ID"),
  };

  // search filters are restored from page state for back navigation
  const [searchQuery, setSearchQuery] = useState<string>(
    isObjectWithKey(history.state, "searchQuery") &&
      typeof history.state.searchQuery === "string"
      ? history.state.searchQuery
      : ""
  );
  const [activeSearchFilter, setActiveSearchFilter] = useState<TFilter>(
    isObjectWithKey(history.state, "activeSearchFilter") &&
      isFilter(history.state.activeSearchFilter)
      ? history.state.activeSearchFilter
      : "fullName"
  );
  useEffect(() => {
    history.replaceState({ activeSearchFilter, searchQuery }, "");
  }, [activeSearchFilter, searchQuery]);

  // used to refetch the results when the search query or active filter changes
  useEffect(() => {
    // Skip the first render, which is triggered by the initial query
    if (hasMounted.current) {
      // Only set a timeout if the search query is completely empty
      // or has three characters or more (for performance reasons)
      if (searchQuery.length === 0 || searchQuery.length >= 3) {
        // Only refetch if the user has stopped typing for 700ms (middle
        // ground between responsiveness and slow typers)
        refetchTimeout.current = setTimeout(() => {
          if (activeSearchFilter === "fullName") {
            refetch({ fullName: searchQuery, inmateIdentification: "" });
          } else if (activeSearchFilter === "inmateIdentification") {
            refetch({ fullName: "", inmateIdentification: searchQuery });
          }
        }, 700);
      }
    } else {
      hasMounted.current = true;
    }
    return () => {
      if (refetchTimeout.current) clearTimeout(refetchTimeout.current);
    };
  }, [hasMounted, refetch, activeSearchFilter, searchQuery]);

  return (
    <>
      <Header
        title={t("Residents")}
        subtitle={t(
          "Manage the residents of your facility, access detailed information, and edit their info as needed."
        )}
      >
        <SelectableFieldStringFilter
          onSelectChange={(value) => setActiveSearchFilter(value as TFilter)}
          onTextChange={(value) => setSearchQuery(value)}
          selected={activeSearchFilter}
          text={searchQuery}
          filterToLabelMap={FILTER_TO_LABEL_MAP}
          key="selectableStringFilter"
        />
      </Header>
      <InmateTable
        inmates={data?.facility.newInmates.edges.map((e) => e.node) || []}
        loading={loading}
      />
      {!loading && data?.facility.newInmates.pageInfo.hasNextPage && (
        <Alert severity="warning" sx={{ m: 3, mt: 0 }}>
          {t(
            "Only the first {{limit}} results are shown. Please refine your search to view more results.",
            { limit: LIMIT }
          )}
        </Alert>
      )}
    </>
  );
}
