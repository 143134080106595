import {
  Interval,
  Kiosk,
  MeetingType,
  OrganizationMembership,
} from "@src/api/graphql";

export type Data = {
  inmateId?: string;
  kiosk?: AvailableKiosk;
  availability?: Availability;
  meetingType?: MeetingType;
  registeredGuests?: RegisteredGuest[];
  unregisteredGuests?: UnregisteredGuest[];
  customTimeChosen?: boolean;
  confidential?: boolean;
};

export type AvailableKiosk = Pick<Kiosk, "id" | "name" | "capacity"> & {
  enabled?: boolean;
};

export type Availability = {
  interval: Pick<Interval, "startAt" | "endAt">;
  availableKiosks: AvailableKiosk[];
  conflict: boolean;
};

export type RegisteredGuest = {
  id: string;
  email: string;
  fullName: string;
  connectionId: string;
  organizationMembership?: Pick<OrganizationMembership, "id"> | null;
};

export type UnregisteredGuest = {
  name: string;
};

export type GuestListData = {
  registeredGuests: RegisteredGuest[];
  unregisteredGuests: UnregisteredGuest[];
  confidential?: boolean;
};

export const selectInmateStep = "selectInmate";

export const defaultSteps = [
  selectInmateStep,
  "selectEventType",
  "addGuests",
  "selectWhen",
  "confirm",
] as const;

export type Step = typeof defaultSteps[number];
