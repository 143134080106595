import { Box, Stack } from "@mui/material";
import { CardSkeleton, TitleSkeleton } from "@src/lib/closet";

export default function SettingsSkeleton() {
  return (
    <Box p={2}>
      <TitleSkeleton />
      <Stack spacing={2} mt={2}>
        <CardSkeleton />
        <CardSkeleton />
      </Stack>
    </Box>
  );
}
