import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MultiSelectChipInput,
  SubmitButton,
  TextInput,
  TextInputBase,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { appendItem } from "@src/api/client";
import { ConnectionStatus, FacilityService } from "@src/api/graphql";
import { GetInmateConnectionsDocument } from "@src/graphql/GetInmateConnections.generated";
import facilityServiceLabel from "@src/lib/facilityServiceLabel";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { fromLocalISODate } from "@src/lib/Time";
import { isRequired } from "@src/lib/validate";
import { endOfDay } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreateConnectionRestrictionsDocument } from "./CreateConnectionRestrictions.generated";
import ExpiresAtInput from "./ExpiresAtInput";

export const RestrictableServices = [
  FacilityService.VideoCall,
  FacilityService.VoiceCall,
  FacilityService.InPersonVisit,
  FacilityService.Emessaging,
] as const;
export type RestrictableService = typeof RestrictableServices[number];

type Data = {
  connectionIds: string[];
  services: RestrictableService[];
  expiresAt: string | null;
  reason: string;
};

type Props = {
  connectionIds: string[];
  inmateId: string;
  restrictableServices: RestrictableService[];
  onClose: () => void;
};

export default function AddRestrictionDialog({
  connectionIds,
  inmateId,
  restrictableServices,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const { data: connectionsData } = useQuery(GetInmateConnectionsDocument, {
    onError: handleApolloError,
    variables: { id: inmateId },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<Data>({
    mode: "onTouched",
    defaultValues: {
      connectionIds,
      services: [],
      expiresAt: null,
      reason: "",
    },
  });

  const [createConnectionRestrictionsMutation] = useMutation(
    CreateConnectionRestrictionsDocument,
    {
      onError: handleApolloError,
      update: (cache, { data }) => {
        if (!data) return;

        data.createConnectionRestrictions.restrictions.forEach(
          (restriction) => {
            cache.modify({
              id: cache.identify({
                __typename: "Connection",
                id: restriction.connection.id,
              }),
              fields: {
                restrictions: appendItem(restriction),
                activeRestrictions: (existing) =>
                  restriction.isActive ? appendItem(restriction) : existing,
              },
            });
          }
        );
      },
      onCompleted: () => {
        onClose();
      },
    }
  );

  const doSubmit = async (data: Data) => {
    if (!data.services.length) return;
    const expiresAt = data.expiresAt
      ? endOfDay(fromLocalISODate(data.expiresAt)).getTime()
      : undefined;
    await createConnectionRestrictionsMutation({
      variables: {
        input: {
          connectionIds: data.connectionIds,
          services: data.services,
          expiresAt,
          reason: data.reason,
        },
      },
    });
  };

  const connections =
    connectionsData?.inmate?.connections.filter(
      (c) => c.status === ConnectionStatus.Active
    ) ?? [];

  return (
    <Dialog
      title={t("Add resident restriction")}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Stack spacing={2}>
          <TextInputBase
            variant="outlined"
            disabled
            label={t("Resident")}
            onChange={() => null}
            value={connectionsData?.inmate?.fullName}
          />
          <MultiSelectChipInput
            control={control}
            name="connectionIds"
            label={t("Restricted contacts")}
            items={connections.map((connection) => ({
              value: connection.id,
              name: connection.visitor.fullName,
            }))}
            rules={isRequired(t("Please select at least one contact."))}
          />

          <MultiSelectChipInput
            control={control}
            name="services"
            label={t("Restricted services")}
            items={restrictableServices.map((s) => ({
              value: s,
              name: facilityServiceLabel(s),
            }))}
            rules={isRequired(
              t("Please select at least one restricted service.")
            )}
          />

          <TextInput
            control={control}
            name="reason"
            label={t("Explanation")}
            multiline
            rows={3}
            rules={isRequired(t("Please provide an explanation."))}
          />

          <ExpiresAtInput control={control} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <SubmitButton
          submitting={isSubmitting}
          onClick={handleSubmit(doSubmit)}
        >
          {t("Save")}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
