import { Box, Stack } from "@mui/material";
import HorizontalBarChart from "@src/lib/HorizontalBarChart";
import { Widget } from "@src/pages/ActivityPage/Widget";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  data: number[];
  labels: string[];
  average: number | string;
};

export default function ChartWithAverage({
  title,
  data,
  labels,
  average,
}: Props) {
  const { t } = useTranslation();
  return (
    <Stack
      spacing={2}
      direction={{
        xs: "column",
        lg: "row",
      }}
      sx={{
        pX: 2,
        borderRadius: 4,
        backgroundColor: "white",
      }}
    >
      <Box flex={1}>
        <Widget title={title}>
          <HorizontalBarChart data={data} labels={labels} />
        </Widget>
      </Box>
      {average && <Widget title={t("Average")} value={average} />}
    </Stack>
  );
}
