import { useSnackbarContext } from "@ameelio/ui";
import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

// used for errors that do not bubble up to the error boundary
// reports to sentry and shows a toast
export default function useApolloErrorHandler() {
  const snackbarContext = useSnackbarContext();
  const { t } = useTranslation();

  return function handleApolloError(error: ApolloError | Error) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV) console.error(error);
    Sentry.captureException(error);
    snackbarContext.alert(
      "error",
      error.message === "Internal Server Error"
        ? t(
            "An unexpected error occurred. Our engineering team has been notified."
          )
        : error.message
    );
  };
}
