import { LockOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { UserLabel } from "@src/lib/LiveCall";
import VideoCall from "@src/lib/LiveCall/VideoCall";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  participantNamesById: Record<string, string>;
  scheduledEnd: number;
  kioskName: string;
  onTerminate?: () => Promise<void>;
}

const ConfidentialVideo: React.FC<Props> = ({
  participantNamesById,
  scheduledEnd,
  kioskName,
  onTerminate,
}) => {
  const { t } = useTranslation();

  return (
    <VideoCall
      showSendAlert={false}
      scheduledEnd={scheduledEnd}
      kioskName={kioskName}
      hideVolume={true}
      onTerminate={onTerminate}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        pt: 4,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LockOutlined sx={{ color: "white" }} />
        <Typography variant="h3" component="label" color="white">
          {t("Confidential")}
        </Typography>
      </Box>
      <UserLabel name={Object.values(participantNamesById).join(", ")} />
    </VideoCall>
  );
};

export default ConfidentialVideo;
