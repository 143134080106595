import { Avatar as Base, AvatarProps as BaseProps } from "@mui/material";
import { generateBgColor, getInitials } from "./Common";

interface AvatarProps extends BaseProps {
  fallback: string;
  fallbackIcon?: React.ReactElement;
  size?: number;
  // If false, only initials will be shown
  showFull?: boolean;
}

const inferAvatarFontSize = (name: string) => {
  if (name.length <= 3) {
    return "1.25rem";
  } else if (name.length <= 5) {
    return "0.8rem";
  } else {
    return "0.6rem";
  }
};

const Avatar = ({
  fallback,
  fallbackIcon,
  size,
  src,
  showFull,
  style,
  ...props
}: AvatarProps) => {
  const name = showFull ? fallback : getInitials(fallback);

  const baseStyle = {
    backgroundColor: generateBgColor(fallback),
    width: size || 48,
    height: size || 48,
  };

  return (
    <Base
      src={src || undefined}
      imgProps={{
        sx: {
          backgroundColor: "white",
        },
      }}
      alt={name}
      variant="square"
      style={{
        ...baseStyle,
        ...style,
        fontSize: inferAvatarFontSize(name),
        overflowWrap: "anywhere",
        textAlign: "center",
      }}
      {...props}
    >
      {fallbackIcon || name}
    </Base>
  );
};

export default Avatar;
