import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { SoundOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import {
  Entitlement,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
} from "@src/api/graphql";
import EmptyPage from "@src/lib/EmptyPage";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import InmateCell from "@src/lib/InmateCell";
import NotAllowed from "@src/lib/NotAllowed";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { formatDate } from "@src/lib/Time";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import VisitorList from "@src/lib/VisitorList";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetFacilityCallsDocument } from "./GetFacilityCalls.generated";
import VoiceCallAudio, { VoiceCall } from "./VoiceCallAudio";

const LiveVoiceCallMonitoringPage = (props: Record<string, never>) => {
  const { facility } = useGuaranteedFacilityContext();
  const user = useCurrentStaff();
  const [activeVoiceCall, setActiveVoiceCall] = useState<VoiceCall>();
  const canMonitorLiveCalls = useEntitlement(Entitlement.MonitorLiveCalls);
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();

  // add query to get live calls for facility
  const { data, loading, refetch } = useQuery(GetFacilityCallsDocument, {
    variables: {
      id: facility.id,
      meetingType: [MeetingType.VoiceCall],
      meetingStatus: [MeetingStatus.Live],
      skip: !canMonitorLiveCalls,
      onError: handleApolloError,
    },
    pollInterval: 30000,
    fetchPolicy: "network-only",
  });

  const voiceCalls = useMemo(
    () => data?.facility.meetings.edges.map(({ node }) => node) || [],
    [data]
  );
  // const peer = peers[0];
  // TODO: initialize room client and start streaming audios that are consumed
  // https://app.shortcut.com/ameelio/story/2035/when-user-selects-a-voice-call-to-listen-in-connect-doc-to-video-handler-and-stream-consumed-audio
  const onListen = (vc: VoiceCall) => {
    activeVoiceCall?.id === vc.id
      ? setActiveVoiceCall(undefined)
      : setActiveVoiceCall(vc);
  };

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: voiceCalls,
  });

  if (loading) return <PageLoader />;

  //  render
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minHeight: "100vh",
      }}
    >
      <Header
        title={`${t("Live Voice Calls")} (${voiceCalls.length})`}
        subtitle={t("Monitor and terminate voice calls if needed.")}
      />
      {!canMonitorLiveCalls ? (
        <NotAllowed />
      ) : voiceCalls.length === 0 ? (
        <EmptyPage
          description={t("There currently aren't any live voice calls.")}
        />
      ) : (
        <Box p={3}>
          {activeVoiceCall && (
            <VoiceCallAudio
              vc={activeVoiceCall}
              user={user}
              onStopListening={() => {
                setActiveVoiceCall(undefined);
                refetch();
              }}
            />
          )}
          <TableContainer>
            <Table aria-label={t("Live voice calls table")}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Start time")}</TableCell>
                  <TableCell>{t("Residents")}</TableCell>
                  <TableCell>{t("Guests")}</TableCell>
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.map((call) => {
                  const isSelected = call.id === activeVoiceCall?.id;
                  return (
                    <TableRow key={call.id}>
                      <TableCell>
                        <span>
                          {formatDate(new Date(call.interval.startAt), "time")}
                        </span>
                      </TableCell>
                      <TableCell>
                        {call.inmates.map((inmate) => (
                          <InmateCell inmate={inmate} key={inmate.id} />
                        ))}
                      </TableCell>
                      <TableCell>
                        <VisitorList
                          visitors={call.visitors}
                          unregisteredGuests={[]}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="inline-block">
                          <Button
                            variant={isSelected ? "contained" : "outlined"}
                            startIcon={<SoundOutlined />}
                            disabled={
                              !canMonitorLiveCalls ||
                              call.privacyLevel !== PrivacyLevel.Monitored
                            }
                            disabledReason={
                              !canMonitorLiveCalls
                                ? t(
                                    "You do not have permission to monitor live voice calls."
                                  )
                                : t("This call cannot be monitored.")
                            }
                            onClick={() => onListen(call)}
                          >
                            {isSelected ? t("Listening") : t("Listen")}
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            totalCount={voiceCalls.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default LiveVoiceCallMonitoringPage;
