import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  column?: number;
  children?: React.ReactNode;
};

function Descriptions({ column = 1, children }: Props) {
  return (
    <Box display="grid" gridTemplateColumns={`repeat(${column}, 1fr)`} gap={2}>
      {children}
    </Box>
  );
}

Descriptions.Item = function Item({
  label,
  children,
  style,
}: {
  label: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <Box display="flex" alignItems="center" gap={1} style={style}>
      <Typography variant="subtitle2">{label}:</Typography>
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

export default Descriptions;
