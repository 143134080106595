import { useCallback, useState } from "react";

export default function useBoolean() {
  const [current, setCurrent] = useState(false);
  const enable = useCallback(() => setCurrent(true), []);
  const disable = useCallback(() => setCurrent(false), []);
  const toggle = useCallback(() => setCurrent((val) => !val), []);
  return {
    current,
    disable,
    enable,
    toggle,
  };
}
