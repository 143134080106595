import { Button, useSnackbarContext } from "@ameelio/ui";
import { DownloadOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import { RecordingStatus } from "@src/api/graphql";
import RequestRecordingButton from "@src/lib/RequestRecordingButton";
import Result from "@src/lib/Result";
import ScreenSection from "@src/lib/ScreenSection";
import { GetCallWithRecordingQuery } from "@src/pages/ArtifactsPage/GetCallWithRecording.generated";
import { GetRecordingUrlDocument } from "@src/pages/ArtifactsPage/GetRecordingUrl.generated";
import RecordingPlaceholder from "@src/pages/ArtifactsPage/RecordingPlaceholder";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

type RecordingProps = {
  meeting: GetCallWithRecordingQuery["meeting"];
};

const DownloadRecordingButton = ({
  meeting,
}: {
  meeting: GetCallWithRecordingQuery["meeting"];
}) => {
  const [signedVideoUrl, setSignedVideoUrl] = useState<string>();
  const [downloading, setDownloading] = useState(false);
  const snackbarContext = useSnackbarContext();
  const { t } = useTranslation();
  const [fetchRecordingUrl] = useLazyQuery(GetRecordingUrlDocument, {
    variables: { id: meeting.id },
    fetchPolicy: "network-only",
    onError: () => {
      snackbarContext.alert("error", t("Fetch of recording url failed."));
    },
  });
  return (
    <Button
      onClick={async () => {
        setDownloading(true);
        let url = signedVideoUrl;
        if (!url) {
          const { data: urlData } = await fetchRecordingUrl();
          url = urlData?.meeting.call?.recording?.url || undefined;
          setSignedVideoUrl(url);
        }
        if (url) {
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          snackbarContext.alert("error", t("Fetch of recording url failed."));
        }
        setDownloading(false);
      }}
      key="2"
      variant="contained"
      disabled={
        meeting.call?.recording?.status !== RecordingStatus.Available ||
        downloading
      }
    >
      <DownloadOutlined style={{ verticalAlign: "middle" }} />
      <span style={{ verticalAlign: "middle", marginLeft: 8 }}>
        {t("Download")}
      </span>
    </Button>
  );
};

export default function RecordingModule({ meeting }: RecordingProps) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const [signedVideoUrl, setSignedVideoUrl] = useState<string>();
  const [showPlayer, setShowPlayer] = useState(false);

  const [fetchRecordingUrl] = useLazyQuery(GetRecordingUrlDocument, {
    variables: { id: meeting.id },
    fetchPolicy: "network-only",
    onError: () => {
      snackbarContext.alert("error", t("Fetch of recording url failed."));
    },
  });

  const recordingStatus = meeting?.call?.recording?.status;
  const errorTitle = (() => {
    switch (recordingStatus) {
      case RecordingStatus.NotStarted:
        return t("No recording available");
      case RecordingStatus.Pending:
        return t("In progress");
      case RecordingStatus.Expired:
        return t("Expired link");
    }
    return t("Unavailable");
  })();

  const errorSubtitle = (() => {
    switch (recordingStatus) {
      case RecordingStatus.NotStarted:
        return t("");
      case RecordingStatus.Pending:
        return t(
          "This recording is still processing. Please check back later."
        );
      case RecordingStatus.Expired:
        return t(
          "All recordings permanently expire {{count}} days after the meeting start time unless previously downloaded.",
          {
            count: meeting.facility.system.recordingRetention,
          }
        );
    }
    return t("This recording is not available.");
  })();

  return (
    <ScreenSection
      title={t("Recordings")}
      sx={{ mb: 2 }}
      titlePosition="inner"
      extra={
        meeting.call?.recording?.status === RecordingStatus.Available ? (
          <DownloadRecordingButton meeting={meeting} />
        ) : null
      }
    >
      {recordingStatus !== RecordingStatus.Available && (
        <Result
          variant="404"
          title={errorTitle}
          subTitle={errorSubtitle}
          extra={
            recordingStatus === RecordingStatus.NotStarted && (
              <RequestRecordingButton meeting={meeting} />
            )
          }
        />
      )}
      {recordingStatus === RecordingStatus.Available && (
        <Stack m={2} direction={{ xs: "column", md: "row" }} spacing={2}>
          {recordingStatus === RecordingStatus.Available && (
            <Box
              flex={2}
              sx={{
                position: "relative",
                minHeight: { xs: "400px" },
              }}
            >
              {!showPlayer && (
                <RecordingPlaceholder
                  style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  names={[
                    ...meeting.inmates.map((r) => r.fullName),
                    ...meeting.visitors.map((v) => v.fullName),
                  ]}
                  onClick={async () => {
                    let url = signedVideoUrl;
                    if (!url) {
                      const { data } = await fetchRecordingUrl();
                      url = data?.meeting.call?.recording?.url || undefined;
                      setSignedVideoUrl(url);
                    }
                    if (url) {
                      setShowPlayer(true);
                    } else {
                      snackbarContext.alert(
                        "error",
                        t("Fetch of recording url failed.")
                      );
                    }
                  }}
                />
              )}
              {showPlayer && signedVideoUrl && (
                <ReactPlayer
                  controls={true}
                  width={"100%"}
                  height={"100%"}
                  style={{
                    position: "absolute",
                    top: 0,
                  }}
                  url={signedVideoUrl}
                />
              )}
            </Box>
          )}
        </Stack>
      )}
    </ScreenSection>
  );
}
