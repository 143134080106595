import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps as BaseProps,
  SubmitButton,
  TextInput,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import { appendItem } from "@src/api/client";
import { Entitlement, MeetingType } from "@src/api/graphql";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useEntitlement from "@src/lib/useEntitlement";
import {
  hasMaxValue,
  hasMinValue,
  isRequired,
  mergeRules,
} from "@src/lib/validate";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConditionalTooltip from "../../lib/ConditionalTooltip";
import { CreateKioskDocument } from "./CreateKiosk.generated";
import { UpdateKioskDocument } from "./UpdateKiosk.generated";

const DEFAULT_CALL_KIOSK_CAPACITY = 6; // As observed from looking in the DB

type TextFormData = {
  name: string;
  capacity: number;
};

type Props = Omit<BaseProps, "open" | "onSubmit" | "title"> & {
  isInPerson: boolean;
  kiosk?: {
    id: string;
    capacity: number;
    name: string;
  };
  onClose: () => void;
};

export default function AddEditKioskModal({
  kiosk,
  isInPerson,
  onClose,
  children,
  ...rest
}: Props) {
  const { facility } = useGuaranteedFacilityContext();
  const canEdit = useEntitlement(Entitlement.ManageFacility);

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    setFocus,
  } = useForm<TextFormData>({
    mode: "onChange",
    defaultValues: {
      name: kiosk ? kiosk.name : "",
      capacity: kiosk ? kiosk.capacity : DEFAULT_CALL_KIOSK_CAPACITY,
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setFocus("name");
    }, 0);
  }, [setFocus]);
  const handleApolloError = useApolloErrorHandler();

  const [updateKiosk] = useMutation(UpdateKioskDocument, {
    onError: handleApolloError,
  });

  const [createKiosk] = useMutation(CreateKioskDocument, {
    onError: handleApolloError,
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          id: cache.identify({ __typename: "Facility", id: facility.id }),
          fields: {
            kiosks: appendItem(data.createKiosk.kiosk),
          },
        });
      }
    },
  });

  const onFormSubmit = handleSubmit(async (data) => {
    if (kiosk) {
      updateKiosk({
        variables: {
          input: {
            kioskId: kiosk.id,
            name: data.name,
            capacity: Number(data.capacity),
          },
        },
      });
    } else {
      await createKiosk({
        variables: {
          input: {
            facilityId: facility.id,
            name: data.name,
            meetingType: isInPerson
              ? MeetingType.InPersonVisit
              : MeetingType.VideoCall,
            capacity: Number(
              data.capacity ? data.capacity : DEFAULT_CALL_KIOSK_CAPACITY
            ), // Default for 'Call' meeting types ('capacity' will be undefined)
            enabled: true,
          },
        },
      });
    }
    onClose();
  });

  const { t } = useTranslation();
  const dialogTitle = (() => {
    // Edit
    if (kiosk) {
      return isInPerson ? t("Edit table") : t("Edit computer");
    }
    // Add
    return isInPerson ? t("Add table") : t("Add computer");
  })();

  const inputPlaceholder = isInPerson ? t("Table name") : t("Computer name");

  return (
    <Dialog {...rest} title={dialogTitle} onClose={onClose} fullWidth>
      <form onSubmit={onFormSubmit}>
        <DialogContent sx={{ pt: 0 }}>
          <Stack spacing={2.5}>
            <ConditionalTooltip
              active={!canEdit}
              title={t("You do not have permission to edit kiosks.")}
            >
              <TextInput
                sx={{ mt: 2, width: 1 }}
                control={control}
                name="name"
                label={t("Name")}
                placeholder={inputPlaceholder}
                disabled={!canEdit}
                rules={isRequired(t("Please provide a name."))}
              />
            </ConditionalTooltip>
            {isInPerson && (
              <ConditionalTooltip
                active={!canEdit}
                title={t("You do not have permission to edit kiosks.")}
              >
                <TextInput
                  sx={{ mt: 2, width: 1 }}
                  control={control}
                  name="capacity"
                  label={t("Capacity")}
                  placeholder={""}
                  disabled={!canEdit}
                  helperText={t("The total number of people who can fit here")}
                  rules={mergeRules(
                    isRequired(t("Please provide a capacity")),
                    hasMinValue(1),
                    hasMaxValue(30)
                  )}
                />
              </ConditionalTooltip>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          {canEdit ? (
            <>
              <Button variant="outlined" autoFocus onClick={onClose}>
                {t("Cancel")}
              </Button>
              <SubmitButton disabled={!isValid} submitting={isSubmitting}>
                {t("Save")}
              </SubmitButton>
            </>
          ) : (
            <Button variant="contained" autoFocus onClick={onClose}>
              {t("Close")}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}
