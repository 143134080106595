import MenuListBase from "@mui/material/MenuList";
import Dropdown, { DropdownItem, SubmenuItem } from "@src/lib/Dropdown";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import { Facility } from "@src/typings/Facility";
import React from "react";
import { useTranslation } from "react-i18next";
import Avatar from "./Avatar";

export default function CornerMenu({
  children,
  onSelectFacility,
}: {
  children: React.ReactElement;
  onSelectFacility: (facility: Facility) => void;
}) {
  const navigate = useAppNavigate();
  const { facility: selectedFacility } = useGuaranteedFacilityContext();
  const user = useCurrentStaff();

  const { t } = useTranslation();

  return (
    <Dropdown
      key={selectedFacility.id} // causes tooltip to disappear on select
      menu={
        <MenuListBase>
          {user.staffPositions.length > 1 && (
            <SubmenuItem
              label={t("Switch facility")}
              sx={{ maxWidth: { xs: "100%", sm: "600px" } }}
              menu={
                <MenuListBase>
                  {user.staffPositions.map((sp) => (
                    <DropdownItem
                      key={sp.facility.id}
                      onClick={() => onSelectFacility(sp.facility)}
                      image={
                        <Avatar
                          fallback={sp.facility.publicId}
                          showFull
                          style={{ fontSize: "inherit" }}
                        />
                      }
                      label={sp.facility.name}
                    />
                  ))}
                </MenuListBase>
              }
            />
          )}
          <DropdownItem
            label={t("Manage system")}
            onClick={() => navigate("/system")}
          />
          <DropdownItem
            label={t("My preferences")}
            onClick={() => navigate("/preferences")}
          />
          <DropdownItem
            label={t("Log out")}
            onClick={() => navigate("/logout")}
          />
        </MenuListBase>
      }
      placement="below"
      sx={{ width: "300px" }}
    >
      {children}
    </Dropdown>
  );
}
