import { Tooltip } from "@ameelio/ui";
import { MenuItem, MenuItemProps } from "@mui/material";
import Typography from "@mui/material/Typography";

type Props = MenuItemProps & {
  disabledReason?: string;
};

export default function ContextMenuItem({
  children,
  disabledReason,
  ...rest
}: Props) {
  return (
    <Tooltip title={disabledReason} arrow>
      {/* Fixes https://github.com/mui/material-ui/issues/31261 */}
      <span>
        <MenuItem ref={rest.ref} {...rest}>
          {/* Necessary because MenuItem ignores color prop */}
          {rest.color ? (
            <Typography color={rest.color}>{children}</Typography>
          ) : (
            children
          )}
        </MenuItem>
      </span>
    </Tooltip>
  );
}
