import { SelectInputBase } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { Inmate } from "@src/api/graphql";
import { GetConnectionMessagesDocument } from "@src/graphql/GetConnectionMessages.generated";
import { GetInmateConnectionsDocument } from "@src/graphql/GetInmateConnections.generated";
import { TypographyText } from "@src/lib/typography";
import useMountedPolling from "@src/lib/useMountedPolling";
import MessagePanelStructure from "@src/pages/MessageReviewPage/MessageReviewPanel/MessagePanelStructure";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MessagesList from "../../../pages/MessageReviewPage/MessageReviewPanel/MessagesList";
import MessagesListHeader from "../../../pages/MessageReviewPage/MessageReviewPanel/MessagesListHeader";
import DateRangePicker from "../../DateRangePicker";
import { TConnectionListItem } from "./ConnectionList/ConnectionListItem";
import MessagesCSVExport from "./MessagesCSVExport";
import MessagesListHeaderContent from "./MessagesListHeaderContent";

interface Props {
  inmate: Pick<
    Inmate,
    "id" | "firstName" | "lastName" | "fullName" | "inmateIdentification"
  >;
}

const MessagesReadOnlyPanel = ({ inmate }: Props) => {
  const [selected, setSelected] = useState<TConnectionListItem | undefined>();
  const [startDate, setStartDate] = useState<number | null>();
  const [endDate, setEndDate] = useState<number | null>();
  const { data: connectionData } = useQuery(GetInmateConnectionsDocument, {
    variables: { id: inmate.id },
  });
  const connections = connectionData?.inmate.connections || [];
  const {
    data: messagesData,
    loading,
    startPolling,
    stopPolling,
  } = useQuery(GetConnectionMessagesDocument, {
    variables: { id: selected?.id || "" },
    skip: !selected?.id,
  });
  useMountedPolling(30000, startPolling, stopPolling);

  const { t } = useTranslation();

  const allMessages =
    messagesData?.connection.messages.edges.map((e) => e.node) || [];

  const filteredMessages = useMemo(() => {
    let messages =
      messagesData?.connection.messages.edges.map((e) => e.node) || [];
    if (startDate) {
      const start = new Date(startDate);
      messages = messages.filter(
        (message) => new Date(message.createdAt) >= start
      );
    }
    if (endDate) {
      const end = new Date(endDate);
      messages = messages.filter(
        (message) => new Date(message.createdAt) <= end
      );
    }
    return messages;
  }, [messagesData, startDate, endDate]);

  return (
    <>
      <SelectInputBase
        size="small"
        label={t("Contact")}
        items={connections
          .map((c) => {
            return {
              name: c.visitor.fullName,
              value: c.id,
            };
          })
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          })}
        onChange={(e) => {
          setSelected(connections.find((c) => c.id === e.target.value));
        }}
        value={selected?.id}
        sx={{
          mb: 2,
          maxWidth: {
            xs: "100%",
            md: "50%",
          },
        }}
      />
      {selected && allMessages.length ? (
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            spacing={2}
          >
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </Stack>

          <MessagesCSVExport
            messages={filteredMessages}
            inmate={inmate}
            visitor={selected.visitor}
            startDate={
              startDate
                ? startDate
                : filteredMessages.length && filteredMessages[0].createdAt
            }
            endDate={endDate ? endDate : new Date().getTime()}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          width: "100%",
        }}
      >
        <MessagePanelStructure
          hasSelectedItem={Boolean(selected)}
          messagePanelContent={
            selected && (
              <>
                <MessagesListHeader className="justify-between pl-5 pr-5">
                  <MessagesListHeaderContent visitor={selected.visitor} />
                </MessagesListHeader>
                {filteredMessages.length > 0 ? (
                  <MessagesList messages={filteredMessages} />
                ) : (
                  <TypographyText
                    type="secondary"
                    sx={{
                      m: "0 auto",
                      p: 4,
                    }}
                  >
                    {loading
                      ? t("Loading...")
                      : t("There are no messages in this conversation.")}
                  </TypographyText>
                )}
              </>
            )
          }
        />
      </Box>
    </>
  );
};

export default MessagesReadOnlyPanel;
