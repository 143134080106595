import { Button } from "@ameelio/ui";
import * as Sentry from "@sentry/react";
import Result from "@src/lib/Result";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorBoundary: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => (
        <div className="h-screen flex items-center justify-center">
          <Result
            variant="error"
            title={t("Sorry, something went wrong.")}
            subTitle={t(
              "An unexpected error has occurred. Please report it to an Ameelio team member."
            )}
            extra={[
              <Button
                key="reset"
                variant="outlined"
                onClick={() => {
                  window.history.back();
                  resetError();
                }}
              >
                {t("Go back")}
              </Button>,
            ]}
          />
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
