import { Box } from "@mui/material";
import Spinner from "@src/lib/Spinner";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function PageLoader({
  title,
}: {
  title?: string;
}): ReactElement {
  const { t } = useTranslation();
  return (
    <Box
      className="h-full w-full flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner title={title || t("Loading page...")} className="m-auto" />
    </Box>
  );
}
