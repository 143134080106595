import { lightPalette } from "@ameelio/ui";
import { CheckCircle, Error } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { ReactComponent as Icon404 } from "@src/assets/404.svg";

export type ResultStatus = "success" | "error" | "404";

type ResultProps = {
  icon?: React.ReactNode;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  variant?: ResultStatus;
  extra?: React.ReactNode;
  children?: React.ReactNode;
};

const BASE_ICON_STYLE = {
  fontSize: "5rem",
};

const getIcon = (status?: ResultStatus, iconStyle?: React.CSSProperties) => {
  switch (status) {
    case "success":
      return <CheckCircle sx={iconStyle} />;
    case "error":
      return <Error sx={iconStyle} />;
    case "404":
      return <Icon404 style={{ width: "250px" }} />;
    default:
      return null;
  }
};

const Result = ({
  icon,
  title,
  subTitle,
  variant,
  extra,
  children,
}: ResultProps) => {
  const iconColor =
    variant === "error" ? lightPalette.error.main : lightPalette.info.main;
  const iconStyle = { ...BASE_ICON_STYLE, color: iconColor };
  const displayIcon = icon ? (
    <Box sx={iconStyle}>{icon}</Box>
  ) : (
    getIcon(variant, iconStyle)
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        p: 4,
      }}
    >
      <Stack alignItems="center" spacing={3}>
        {displayIcon}
        <Box>
          <Typography variant="h3" component="div">
            {title}
          </Typography>
          {subTitle && (
            <Typography variant="subtitle1" color={lightPalette.text.secondary}>
              {subTitle}
            </Typography>
          )}
        </Box>
        {children && (
          <Box
            sx={{
              width: "100%",
              p: 2,
              backgroundColor: lightPalette.grey["100"],
            }}
          >
            {children}
          </Box>
        )}
        {extra && <Stack>{extra}</Stack>}
      </Stack>
    </Box>
  );
};

export default Result;
