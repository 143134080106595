import {
  CircularProgress,
  CircularProgressProps,
  Stack,
  Typography,
} from "@mui/material";

export default function Spinner(props: CircularProgressProps) {
  return (
    <Stack spacing={2}>
      <CircularProgress size="24px" {...props} />
      {props.title && <Typography variant="body1">{props.title}</Typography>}
    </Stack>
  );
}
