import {
  Button,
  TableNoData,
  TablePagination,
  usePagination,
} from "@ameelio/ui";
import { Box, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MeetingType } from "@src/api/graphql";
import getCallEndTime from "@src/lib/getCallEndTime";
import MeetingContextMenu from "@src/lib/MeetingContextMenu";
import MeetingsTableMeeting from "@src/lib/Profiles/MeetingsTableMeeting";
import { formatDate } from "@src/lib/Time";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";
import InmateCell from "../InmateCell";
import { labelMeetingType } from "../meeting";
import MeetingStatusTag from "../MeetingStatusTag";
import Spinner from "../Spinner";
import VisitorList from "../VisitorList";

type Props = {
  meetings: MeetingsTableMeeting[];
  loading: boolean;
  viewingProfileOf: "inmate" | "visitor";
};

export default function MeetingsTable({
  meetings,
  loading,
  viewingProfileOf,
}: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: meetings,
  });

  return loading ? (
    <Box p={8}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
        <Spinner />
        <Typography variant="body2" color="text.primary">
          {t("Loading...")}
        </Typography>
      </Box>
    </Box>
  ) : (
    <>
      <Table aria-label={t("Meetings table")}>
        <TableHead>
          <TableRow>
            <TableCell>{t("Time")}</TableCell>
            <TableCell>{t("Type")}</TableCell>
            <TableCell>{t("Contact(s)")}</TableCell>
            <TableCell>{t("Status")}</TableCell>
            <TableCell>{t("Actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meetings.length === 0 && <TableNoData colSpan={5} />}
          {meetings.length > 0 &&
            currentPageData.map((meeting) => {
              return (
                <TableRow key={meeting.id}>
                  <TableCell>
                    <div className="flex flex-col">
                      <span>
                        {formatDate(new Date(meeting.interval.startAt), "date")}
                      </span>
                      <span>
                        {formatDate(new Date(meeting.interval.startAt), "time")}{" "}
                        - {""}
                        {getCallEndTime(
                          meeting.status,
                          meeting.meetingType,
                          meeting.interval,
                          meeting.call?.duration,
                          meeting.meetingType === MeetingType.VoiceCall
                        )}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-col">
                      {labelMeetingType(meeting.meetingType, {
                        titleCase: true,
                      })}
                    </div>
                  </TableCell>
                  {viewingProfileOf === "inmate" && (
                    <TableCell>
                      <VisitorList
                        visitors={meeting.visitors}
                        unregisteredGuests={meeting.unregisteredGuests}
                        combineMinorGuests={
                          meeting.facility.province === "Iowa"
                        }
                      />
                    </TableCell>
                  )}
                  {viewingProfileOf === "visitor" && (
                    <TableCell>
                      <Stack spacing={1}>
                        {meeting.inmates.map((inmate) => (
                          <InmateCell key={inmate.id} inmate={inmate} />
                        ))}
                      </Stack>
                    </TableCell>
                  )}
                  <TableCell>
                    <MeetingStatusTag
                      status={meeting.status}
                      meetingType={meeting.meetingType}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => navigate(`/meetings/${meeting.id}`)}
                      >
                        {t("See details")}
                      </Button>
                      <MeetingContextMenu meeting={meeting} />
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        totalCount={meetings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
    </>
  );
}
