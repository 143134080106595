import { FacilityFeature, MeetingType } from "@src/api/graphql";
import i18n from "@src/i18n/i18nConfig";
import { toTitleCase } from "./Common";

export const labelMeetingType = (
  meetingType: MeetingType,
  opt?: { titleCase?: boolean }
): string => {
  const LABELS: Record<MeetingType, string> = {
    [MeetingType.VideoCall]: i18n.t("video call"),
    [MeetingType.InPersonVisit]: i18n.t("in-person visit"),
    [MeetingType.VoiceCall]: i18n.t("voice call"),
    [MeetingType.Webinar]: i18n.t("webinar"),
  };

  const label = LABELS[meetingType];
  return opt?.titleCase ? toTitleCase(label) : label;
};

// TODO revisit design here
export const meetingTypeTitle = (meetingType: MeetingType[]) => {
  const TITLES: Record<MeetingType, string> = {
    [MeetingType.VideoCall]: i18n.t("Video Call"),
    [MeetingType.InPersonVisit]: i18n.t("In-Person Visit"),
    [MeetingType.VoiceCall]: i18n.t("Voice Call"),
    [MeetingType.Webinar]: i18n.t("Webinar"),
  };
  return meetingType.map((t) => TITLES[t]).join("/");
};

const data: Record<MeetingType, FacilityFeature> = {
  [MeetingType.InPersonVisit]: FacilityFeature.InPersonVisit,
  [MeetingType.VideoCall]: FacilityFeature.VideoCall,
  [MeetingType.VoiceCall]: FacilityFeature.VoiceCall,
  [MeetingType.Webinar]: FacilityFeature.Providers,
};
export default function featureForMeetingType(type: MeetingType) {
  return data[type];
}
