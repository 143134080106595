import { useQuery } from "@apollo/client";
import { MeetingStatus, MeetingType } from "@src/api/graphql";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import useFetchAll from "@src/lib/useFetchAll";
import { useState } from "react";
import { GetScheduleMeetingsDocument } from "./GetScheduleMeetings.generated";

export type Filters = {
  meetingType: MeetingType[] | undefined;
  meetingStatus: MeetingStatus[];
  scheduledStartAfter: number;
  scheduledStartBefore: number | undefined;
};

type Props = {
  facilityId: string;
  filters: Filters;
};

const useFullSchedule = ({ facilityId, filters }: Props) => {
  const [loading, setLoading] = useState(true);
  const handleApolloError = useApolloErrorHandler();
  const { data, fetchMore } = useQuery(GetScheduleMeetingsDocument, {
    variables: {
      id: facilityId,
      ...filters,
    },
    onError: handleApolloError,
  });

  useFetchAll({
    pageInfo: data?.facility.meetings.pageInfo,
    fetchMore,
    onComplete: () => setLoading(false),
  });

  return {
    loading,
    data: loading ? [] : data?.facility.meetings.edges.map((e) => e.node) || [],
  };
};

export default useFullSchedule;
