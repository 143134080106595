import { Button, useSnackbarContext } from "@ameelio/ui";
import useFetchAuthenticated from "@src/api/fetchAuthenticated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Result from "@src/lib/Result";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InmateData } from "./useRosterValidation";

type Props = {
  systemId: string;
  inmates: InmateData[];
  onComplete: () => void;
};

const ValidatedResult: React.FC<Props> = ({
  systemId,
  inmates,
  onComplete,
}) => {
  const user = useCurrentStaff();
  const [submitting, setSubmitting] = useState(false);
  const fetchAuthenticated = useFetchAuthenticated();
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await fetchAuthenticated(`systems/${systemId}/roster`, {
        method: "POST",
        body: JSON.stringify({
          inmates: inmates.map((inmate) => ({
            ...inmate,
            dateOfBirth: inmate.dateOfBirth.getTime(),
          })),
          contactEmail: user.email,
        }),
      });
      snackbarContext.alert("success", t("Roster submitted!"));
      onComplete();
    } catch (e) {
      handleApolloError(e as Error);
    }
    setSubmitting(false);
  };

  return (
    <Result
      variant="success"
      title={t("Validation Succeeded")}
      subTitle={t("{{count}} records parsed", { count: inmates.length })}
      extra={[
        <Button
          key="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
        >
          {t("Submit")}
        </Button>,
      ]}
    />
  );
};

export default ValidatedResult;
