import { sortByStrings } from "@ameelio/core";
import { Inmate, VisitorIdentity } from "@src/api/graphql";
import { differenceInYears } from "date-fns";

function visitorAge({ dateOfBirth }: { dateOfBirth?: string | null }) {
  return dateOfBirth
    ? differenceInYears(new Date(), new Date(dateOfBirth))
    : 999;
}

// Date of birth can be null for legacy users that signed up through Letters
export function isMinor(user: { dateOfBirth?: string | null }): boolean {
  return visitorAge(user) < 18;
}

export function isMinorWithoutID(user: {
  dateOfBirth?: string | null;
  identity?: Pick<VisitorIdentity, "id"> | null;
}): boolean {
  return isMinor(user) && !user.identity;
}

export const getFullNameWithId = (
  inmate: Pick<Inmate, "fullName" | "inmateIdentification">
) => {
  return (
    inmate.fullName +
    (inmate.inmateIdentification ? ` (${inmate.inmateIdentification})` : "")
  );
};

export const getInitials = (str: string) => {
  return str
    .trim()
    .split(" ")
    .filter(Boolean)
    .map((n) => n[0].toUpperCase())
    .slice(0, 2)
    .join("");
};

function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 6) - hash);
  }
  return hash;
}

export function generateBgColor(label: string): string {
  const BACKGROUND_COLORS = [
    "#093145",
    "#3C6478",
    "#107896",
    "#43ABC9",
    "#C2571A",
    "#9A2617",
  ];
  return BACKGROUND_COLORS[
    Math.abs(hashCode(label) % BACKGROUND_COLORS.length)
  ];
}

export function isSubstring(candidate: string, main: string) {
  return main.toLowerCase().includes(candidate.toLowerCase());
}

export function capitalize(str: string): string {
  if (!str.length) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toTitleCase(text: string): string {
  return text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

export function alphabetize<T extends string>(arr: readonly T[]) {
  return sortByStrings(arr, (a) => a);
}
