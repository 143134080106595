import { useSnackbarContext } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { UpdateFacilityInput } from "@src/api/graphql";
import { UpdateFacilityDocument } from "@src/graphql/UpdateFacility.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type Properties = Omit<UpdateFacilityInput, "facilityId">;

export default function useUpdateFacilityProperty() {
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();
  const [updateFacility] = useMutation(UpdateFacilityDocument, {
    onError: handleApolloError,
    onCompleted: () => {
      snackbarContext.alert("success", t("Setting saved."));
    },
  });

  const updateProperties = useCallback(
    async (props: Properties) => {
      await updateFacility({
        variables: {
          input: {
            facilityId: facility.id,
            ...props,
          },
        },
      });
    },
    [facility, updateFacility]
  );

  const updateProperty = useCallback(
    async <P extends keyof Properties>(prop: P, value: Properties[P]) => {
      await updateProperties({
        [prop]: value,
      });
    },
    [updateProperties]
  );

  return { updateProperties, updateProperty };
}
