import { onlyMobile } from "@ameelio/ui/src/helpers/responsiveHelpers";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import Info from "@src/lib/Info";
import React from "react";

export enum WidgetRowLayout {
  TWO_THIRD,
  HALF,
}

export function WidgetRow({
  layout,
  children,
}: {
  layout: WidgetRowLayout;
  children: React.ReactNode;
}) {
  const isMobile = onlyMobile(useMediaQuery);
  const layoutStyle = isMobile
    ? {
        gridTemplateColumns: "1fr",
        gridGap: "16px",
      }
    : layout === WidgetRowLayout.TWO_THIRD
    ? {
        gridTemplateColumns: "2fr 1fr",
        gridGap: "16px",
      }
    : {
        gridTemplateColumns: "1fr 1fr",
        gridGap: "16px",
      };
  return (
    <Box
      sx={{
        display: "grid",
        ...layoutStyle,
        marginBottom: "16px",
      }}
    >
      {children}
    </Box>
  );
}

export function Widget({
  title,
  children,
  value,
  explanation,
}: {
  title: string;
  children?: React.ReactNode;
  value?: string | number;
  explanation?: string;
}) {
  return (
    <Box
      key={title}
      sx={{
        backgroundColor: "white",
        padding: 2,
        borderRadius: 4,
        height: "100%", // Ensure the outer Box takes the full height of its container
      }}
    >
      <Stack spacing={2} alignItems="center" sx={{ height: "100%" }}>
        <Box
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent={value ? "center" : "flex-start"}
          width="100%"
        >
          {typeof value !== "undefined" ? (
            <Typography
              sx={{
                fontSize: "4em",
                textDecoration: "none",
                display: "block",
                width: 1,
                textAlign: "center",
                color: "primary.main",
              }}
            >
              {value}
            </Typography>
          ) : (
            children
          )}
        </Box>
        <Typography variant="h3" display="flex" alignItems="center">
          {title}
          {explanation && <Info message={explanation} />}
        </Typography>
      </Stack>
    </Box>
  );
}
