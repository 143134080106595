import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationFragmentFragmentDoc } from '../../graphql/fragments/OrganizationFragment.generated';
export type GetConnectedOrganizationsQueryVariables = Types.Exact<{
  facilityId: Types.Scalars['ID'];
}>;


export type GetConnectedOrganizationsQuery = { __typename: 'Query', facility: { __typename: 'Facility', id: string, connectedOrganizations: Array<{ __typename: 'Organization', id: string, name: string, type: Types.OrganizationType, description: string, websiteLink: string | null, email: string, phone: string, logoImageUrl: string | null, memberships: Array<{ __typename: 'OrganizationMembership', id: string }>, address: { __typename: 'Address', lineOne: string, lineTwo: string | null, city: string, province: string, country: string, postalCode: string } }> } };


export const GetConnectedOrganizationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetConnectedOrganizations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"facilityId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"connectedOrganizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationFragment"}},{"kind":"Field","name":{"kind":"Name","value":"memberships"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}},...OrganizationFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetConnectedOrganizationsQuery, GetConnectedOrganizationsQueryVariables>;