import { Box, Card, Link, Stack, Typography } from "@mui/material";
import { Organization } from "@src/api/graphql";
import organizationTypeLabel from "@src/lib/organizationTypeLabel";
import AddressLine, { AddressProps } from "./AddressLine";
import OrganizationLogo from "./OrganizationLogo";

type Props = {
  organization: Pick<
    Organization,
    | "name"
    | "description"
    | "type"
    | "logoImageUrl"
    | "websiteLink"
    | "email"
    | "phone"
  > &
    AddressProps;
};

export default function OrganizationProfileCard({ organization }: Props) {
  return (
    <Card variant="outlined" sx={{ px: 2, py: 1.5 }}>
      <Stack spacing={2} direction="row">
        <OrganizationLogo organization={organization} size={56} />
        <Stack>
          <Typography variant="h3" component="p">
            {organization.name}
          </Typography>
          <Typography variant="body2">
            {organizationTypeLabel(organization.type)}
          </Typography>
          <Box my={2}>
            <Typography variant="body1">{organization.description}</Typography>
          </Box>
          <Typography variant="body2">
            {organization.websiteLink && (
              <>
                <Link
                  href={organization.websiteLink}
                  sx={{ textDecoration: "none" }}
                >
                  {organization.websiteLink}
                </Link>
                {" • "}
              </>
            )}
            <Link
              href={`mailto: ${organization.email}`}
              sx={{ textDecoration: "none" }}
            >
              {organization.email}
            </Link>
            {" • "}
            {organization.phone}
          </Typography>
          <Typography variant="body2">
            <AddressLine address={organization.address} />
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
