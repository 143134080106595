import { sortByStrings } from "@ameelio/core";
import { Stack, Typography } from "@mui/material";
import { ConnectionStatus, Correspondent } from "@src/api/graphql";
import EmptyPage from "@src/lib/EmptyPage";
import { MemberType } from "@src/typings/Common";
import { useTranslation } from "react-i18next";
import { labelConnectionStatus } from "../Connection";
import ScreenSection from "../ScreenSection";
import ConnectionItem, { Props as ConnectionItemProps } from "./ConnectionItem";

type Props = {
  connections: (ConnectionItemProps["connection"] & {
    inmate: Pick<Correspondent, "fullName">;
    visitor: Pick<Correspondent, "fullName">;
  })[];
  type: MemberType;
};

function ContactListGroup({
  status,
  connections,
  type,
}: Props & { status: ConnectionStatus }) {
  const correspondentProp = type === "inmate" ? "visitor" : "inmate";
  const sortedConnections = sortByStrings(
    connections,
    (c) => c[correspondentProp].fullName
  );
  return (
    <div>
      <Typography variant="overline">
        {labelConnectionStatus(status)}
      </Typography>
      <Stack mx={-2}>
        {sortedConnections.map((c) => (
          <ConnectionItem
            key={c.id}
            connection={c}
            correspondent={c[correspondentProp]}
          />
        ))}
      </Stack>
    </div>
  );
}

export default function ContactsSection({ connections, type }: Props) {
  const pendingContacts = connections.filter(
    (c) => c.status === ConnectionStatus.Pending
  );
  const activeContacts = connections.filter(
    (c) => c.status === ConnectionStatus.Active
  );
  const inactiveContacts = connections.filter((c) =>
    [ConnectionStatus.Inactive, ConnectionStatus.Rejected].includes(c.status)
  );

  const { t } = useTranslation();

  return (
    <ScreenSection title={t("Contacts")} titlePosition="inner">
      {!connections.length && <EmptyPage description={t("No contacts")} />}

      {pendingContacts.length > 0 && (
        <ContactListGroup
          connections={pendingContacts}
          type={type}
          status={ConnectionStatus.Pending}
        />
      )}
      {activeContacts.length > 0 && (
        <ContactListGroup
          connections={activeContacts}
          type={type}
          status={ConnectionStatus.Active}
        />
      )}
      {inactiveContacts.length > 0 && (
        <ContactListGroup
          connections={inactiveContacts}
          type={type}
          status={ConnectionStatus.Inactive}
        />
      )}
    </ScreenSection>
  );
}
