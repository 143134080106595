import { Button, Media, useSnackbarContext } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { PeopleAltOutlined, VideocamOutlined } from "@mui/icons-material";
import { Box, Card, Typography } from "@mui/material";
import { MeetingStatus, MeetingType } from "@src/api/graphql";
import DetailsStack, { DetailsStackItem } from "@src/lib/DetailsStack";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { labelMeetingType } from "@src/lib/meeting";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { formatDate, formatTimeRangeWithZone } from "@src/lib/Time";
import { theme } from "@src/theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreateScheduledMeetingDocument } from "./CreateScheduledMeeting.generated";
import { Data } from "./types";
import { UpdateScheduledMeetingDocument } from "./UpdateScheduleMeeting.generated";

type Props = {
  inmateId: string;
  data: Data;
  meetingId?: string;
  onBack: () => void;
  stepPosition: number;
  numSteps: number;
};

export default function ConfirmStep({
  inmateId,
  data,
  meetingId,
  onBack,
  numSteps,
  stepPosition,
}: Props) {
  // type narrowing
  const { meetingType, availability, kiosk, confidential } = data;
  if (!meetingType) throw new Error("invalid meeting type");
  if (!availability) throw new Error("invalid meeting time");
  if (!kiosk) throw new Error("invalid kiosk");

  const scheduledStart = availability.interval.startAt;
  const scheduledEnd = availability.interval.endAt;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { facility } = useGuaranteedFacilityContext();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();
  // Create mutation
  const [createMeeting, { loading }] = useMutation(
    CreateScheduledMeetingDocument,
    {
      onError: handleApolloError,
      onCompleted: (scheduledData) => {
        snackbarContext.alert(
          "success",
          scheduledData.createScheduledMeeting.meeting.status ===
            MeetingStatus.PendingApproval
            ? t("Meeting has been requested, and is now waiting for review")
            : t("Meeting scheduled successfully")
        );
        navigate(
          `/meetings/${scheduledData.createScheduledMeeting.meeting.id}`
        );
      },
    }
  );

  // Update mutation
  const [updateMeeting, { loading: updateLoading }] = useMutation(
    UpdateScheduledMeetingDocument,
    {
      onError: handleApolloError,
      onCompleted: (d) => {
        const { id, status } = d.updateScheduledMeeting.meeting;
        snackbarContext.alert(
          "success",
          status === MeetingStatus.PendingApproval
            ? t("Meeting has been requested, and is now waiting for review")
            : t("Meeting updated successfully")
        );
        navigate(`/meetings/${id}`);
      },
    }
  );

  return (
    <>
      <Typography variant="h2" color="text.primary">
        {t("Step {{stepPosition}} of {{numSteps}}: Confirm", {
          stepPosition,
          numSteps,
        })}
      </Typography>
      <Card
        variant="outlined"
        sx={{ mt: 3, p: 2, backgroundColor: theme.palette.common.white }}
      >
        <DetailsStack>
          <DetailsStackItem label={t("Type")}>
            <Media
              image={
                meetingType === MeetingType.VideoCall ? (
                  <VideocamOutlined />
                ) : (
                  // In-person visit
                  <PeopleAltOutlined />
                )
              }
              gap={1}
            >
              <Typography variant="body1">
                {labelMeetingType(meetingType, { titleCase: true })}
              </Typography>
            </Media>
          </DetailsStackItem>
          <DetailsStackItem
            label={t("Confidential")}
            value={confidential ? t("Yes") : t("No")}
          />
          <DetailsStackItem
            label={t("Time")}
            value={`${formatDate(
              scheduledStart,
              "dateyear"
            )}, ${formatTimeRangeWithZone(scheduledStart, scheduledEnd)}`}
          />
          <DetailsStackItem label={t("Location/device")} value={kiosk.name} />
          {!!(
            data.registeredGuests?.length || data.unregisteredGuests?.length
          ) && (
            <DetailsStackItem
              label={t("Visitors")}
              value={[
                ...(data.registeredGuests?.map((rg) => rg.fullName) || []),
                ...(data.unregisteredGuests?.map((g) => g.name) || []),
              ].join(", ")}
            />
          )}
        </DetailsStack>
      </Card>
      <Box mt={3} gap={1} display="flex" justifyContent="flex-end">
        <Button variant="outlined" onClick={onBack}>
          {t("Back")}
        </Button>
        <Button
          variant="contained"
          disabled={loading || updateLoading}
          onClick={async () => {
            if (meetingId) {
              // Update existing meeting
              updateMeeting({
                variables: {
                  input: {
                    meetingId,
                    scheduledStart,
                    scheduledEnd,
                    connectionIds:
                      data.registeredGuests?.map((rg) => rg.connectionId) || [],
                    kioskId: kiosk.id,
                    inmateIds: [inmateId],
                    unregisteredGuests: data.unregisteredGuests?.map(
                      (g) => g.name
                    ),
                  },
                },
              });
            } else {
              // Create new meeting
              createMeeting({
                variables: {
                  input: {
                    kioskId: kiosk.id,
                    inmateIds: [inmateId],
                    facilityId: facility.id,
                    scheduledStart,
                    scheduledEnd,
                    connectionIds: [],
                    visitorIds: data.registeredGuests?.map((rg) => rg.id) ?? [],
                    unregisteredGuests: data.unregisteredGuests?.map(
                      (g) => g.name
                    ),
                    meetingType,
                  },
                },
              });
            }
          }}
        >
          {t("Submit")}
        </Button>
      </Box>
    </>
  );
}
