import { MeetingType } from "@src/api/graphql";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type Filters = {
  residentName: string;
  meetingType: MeetingType[] | undefined;
};

const { history } = window;

export default function useMeetingFiltersState() {
  const location = useLocation();
  const [filters, setFilters] = useState<Filters>({
    residentName:
      isObjectWithKey(history.state, "residentName") &&
      typeof history.state.residentName === "string"
        ? history.state.residentName
        : "",
    meetingType:
      isObjectWithKey(history.state, "meetingType") &&
      Array.isArray(history.state.meetingType)
        ? (history.state.meetingType as Filters["meetingType"])
        : location?.state?.meetingType
        ? [location.state.meetingType]
        : undefined,
  });

  useEffect(() => {
    history.replaceState(filters, "");
  }, [filters]);

  return [filters, setFilters] as const;
}
