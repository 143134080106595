import { belowLargeTablet, Chip } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, useMediaQuery as measureScreenWidth } from "@mui/material";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import Descriptions from "@src/lib/Descriptions";
import entitlementLabel from "@src/lib/entitlementLabel";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { WRAPPER_STYLE } from "@src/styles/styles";
import { useTranslation } from "react-i18next";
import { GetStaffDocument } from "./GetStaff.generated";
import StaffTimeline from "./StaffTimeline";

interface Props {
  staffId: string;
}

const StaffDetail = ({ staffId }: Props) => {
  const { facility, system } = useGuaranteedFacilityContext();
  const handleApolloError = useApolloErrorHandler();
  const { loading, data } = useQuery(GetStaffDocument, {
    variables: {
      id: staffId,
      systemId: system.id,
    },
    onError: handleApolloError,
  });
  const { t } = useTranslation();
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  if (loading || !data) return <PageLoader />;

  const { staff } = data;

  return (
    <>
      <Header
        onBack={() => window.history.back()}
        title={staff.fullName}
        breadcrumb={
          <Breadcrumbs
            paths={[
              {
                path: "/system",
                label: system.code,
              },

              {
                path: "/system/staff",
                label: t("Staff"),
              },
              {
                path: `/system/staff/${staff.id}`,
                label: staff.fullName,
              },
            ]}
          />
        }
      >
        <Box mb={2}>
          <Descriptions column={isMobileOrSmallTablet ? 1 : 3}>
            <Descriptions.Item label={t("Full name")}>
              {staff.fullName}
            </Descriptions.Item>
            <Descriptions.Item label={t("Email")}>
              {staff.email}
            </Descriptions.Item>
          </Descriptions>
        </Box>
        <Box mb={2}>
          <Descriptions>
            <Descriptions.Item label={t("Facilities")}>
              {staff.staffPositions.map((sp) => (
                <Chip
                  color="grey"
                  style={{ marginRight: 3 }}
                  key={sp.id}
                  label={sp.facility.publicId}
                />
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Box>
        <Box>
          <Descriptions>
            <Descriptions.Item
              label={t("Entitlements")}
              style={{
                maxWidth: "100%",
                overflow: "auto",
              }}
            >
              {staff.staffEntitlements.map((se) => (
                <Chip
                  color="grey"
                  style={{ marginRight: 3 }}
                  key={se.id}
                  label={entitlementLabel(se.entitlement)}
                />
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Box>
      </Header>
      <div style={WRAPPER_STYLE}>
        <StaffTimeline staffId={staffId} systemId={facility.system.id} />
      </div>
    </>
  );
};

export default StaffDetail;
