import { DialogProps as BaseProps, SelectInput, TextInput } from "@ameelio/ui";
import { Box, InputAdornment } from "@mui/material";
import { useEffect } from "react";
import { UseControllerProps, useForm } from "react-hook-form";
import FormDialog from "./FormDialog";

export type TextFormData = {
  value: string;
};

type InputType = "text" | "email" | "tel";

type Props = Omit<BaseProps, "open" | "onSubmit"> & {
  title: string;
  description?: string;
  placeholder?: string;
  label?: string;
  value: string;
  inputType?: InputType;
  endAdornment?: string;
  rules?: UseControllerProps<TextFormData>["rules"];
  errorMessages?: { [n: string]: string };
  cancelText?: string;
  confirmText?: string;
  selectOptions?: { value: string; name: string }[];
  onCancel: () => void;
  onSubmit: (data: { value: string }) => void | Promise<void>;
};

export default function InputDialog({
  placeholder,
  label,
  value,
  rules,
  errorMessages,
  selectOptions,
  inputType,
  endAdornment,
  onSubmit,
  ...rest
}: Props) {
  const {
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
    control,
    setFocus,
  } = useForm<TextFormData>({
    mode: "onChange",
    defaultValues: {
      value: value.toString(),
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setFocus("value");
    }, 0);
  }, [setFocus]);

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      formState={{ isValid, isSubmitting }}
      {...rest}
    >
      {selectOptions ? (
        <Box sx={{ mt: 2 }}>
          <SelectInput
            aria-label={rest.title}
            name={"value"}
            inputProps={{ "aria-label": rest.title }}
            control={control}
            items={selectOptions}
          />
        </Box>
      ) : (
        <TextInput
          sx={{ mt: 2, width: 1 }}
          rules={rules}
          control={control}
          label={label}
          type={inputType || "text"}
          name="value"
          id={"value"}
          title={inputType || "text"}
          placeholder={placeholder}
          inputProps={{ "aria-label": rest.title }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          helperText={
            errorMessages && errors.value && errors.value.type in errorMessages
              ? errorMessages[errors.value.type]
              : undefined
          }
        />
      )}
    </FormDialog>
  );
}
