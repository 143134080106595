import { IconButton } from "@ameelio/ui";
import { useLazyQuery } from "@apollo/client";
import { Alert, Box, Stack, Typography } from "@mui/material";
import {
  ClearIcon,
  TimePicker,
  TimeValidationError,
} from "@mui/x-date-pickers";
import i18n from "@src/i18n/i18nConfig";
import { CardSkeleton } from "@src/lib/closet";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { theme } from "@src/theme";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetAvailableKiosksDocument } from "./GetAvailableKiosks.generated";
import SelectableKiosk from "./SelectableKiosk";
import { Availability, AvailableKiosk, Data } from "./types";

type TimeRangeError = {
  start: TimeValidationError | null;
  end: TimeValidationError | null;
};

type Value = { availability: Availability; kiosk: AvailableKiosk };

type Props = {
  data: Data;
  selectedDate: Date;
  value?: Value;
  onChange: (value: Value | null) => void;
};

const getErrorMessage = (message: string | null) => {
  switch (message) {
    case "invalidDate": {
      return i18n.t("Please enter a valid date");
    }
    case "disablePast": {
      return i18n.t("This date is in the past");
    }
    case "minDate":
    case "minTime": {
      return i18n.t("The end time is before the start time");
    }
    case "maxDate":
    case "maxTime":
      return i18n.t("The start time is after the end time");
    default: {
      return "";
    }
  }
};

// Used to produce a combined Date object with the "date" parts
// selected from the date the user selected in the `SelectAvailability` component,
// and only the hours/mins parts selected from the Time pickers in this component
const getCombinedDatetime = (selectedDate: Date, selectedTime: Date) => {
  return new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    selectedTime.getHours(),
    selectedTime.getMinutes()
  ).getTime();
};

export default function CustomTimeInput({
  data,
  selectedDate,
  value,
  onChange,
}: Props) {
  // type narrowing
  if (!data.meetingType) throw new Error("invalid meeting type");
  const { meetingType, registeredGuests, unregisteredGuests } = data;
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();

  const [startTime, setStartTime] = useState<Date | undefined>(
    data.availability ? new Date(data.availability.interval.startAt) : undefined
  );
  const [endTime, setEndTime] = useState<Date | undefined>(
    data.availability ? new Date(data.availability.interval.endAt) : undefined
  );

  // Error tracking
  const [error, setError] = useState<TimeRangeError>({
    start: null,
    end: null,
  });

  // Reservable meeting resources
  const [availableKiosks, setAvailableKiosks] = useState<
    AvailableKiosk[] | undefined
  >();
  const handleApolloError = useApolloErrorHandler();
  const [searchAvailableKiosks, { loading: searchingKiosks }] = useLazyQuery(
    GetAvailableKiosksDocument,
    {
      fetchPolicy: "network-only",
      onError: handleApolloError,
      onCompleted: (data) => {
        setAvailableKiosks(data?.facility.availableKiosks);
      },
    }
  );

  const meetingGroupSize =
    1 + // The inmate IS included in the total meeting group size
    (registeredGuests?.length || 0) +
    (unregisteredGuests?.length || 0);

  const doSearch = useCallback(
    (startTime: Date | undefined, endTime: Date | undefined) => {
      if (!startTime || !endTime) return;

      searchAvailableKiosks({
        variables: {
          facilityId: facility.id,
          start: getCombinedDatetime(selectedDate, startTime),
          end: getCombinedDatetime(selectedDate, endTime),
          meetingType,
        },
      });
    },
    [facility, meetingType, selectedDate, searchAvailableKiosks]
  );

  return (
    <Stack spacing={3}>
      <Typography variant="body1" color="text.primary">
        {t("1. Specify a time outside of the standard visitor options.")}
      </Typography>
      <Stack spacing={3}>
        <Box display="flex" flexDirection="row" gap={3}>
          <TimePicker
            name="start"
            label={t("Starts at")}
            value={startTime || null}
            onChange={(value) => {
              if (!value || value?.toString() === "Invalid Date") return;
              setStartTime(value);
              onChange(null);
              doSearch(value, endTime);
            }}
            closeOnSelect={false}
            maxTime={endTime}
            onError={(err) => setError({ ...error, start: err })}
            slotProps={{
              textField: {
                helperText: getErrorMessage(error.start),
                sx: {
                  width: 1,
                  borderRadius: 2,
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.common.white,
                  },
                },
              },
            }}
            slots={{
              openPickerButton: startTime
                ? () => (
                    <>
                      <IconButton
                        onClick={() => {
                          setStartTime(undefined);
                          onChange(null);
                        }}
                        edge="end"
                        ariaLabel={t("Clear")}
                      >
                        <ClearIcon />
                      </IconButton>
                    </>
                  )
                : undefined,
            }}
          />
          <TimePicker
            name="end"
            label={t("Ends at")}
            value={endTime || null}
            onChange={(value) => {
              if (!value || value?.toString() === "Invalid Date") return;
              setEndTime(value);
              onChange(null);
              doSearch(startTime, value);
            }}
            closeOnSelect={false}
            minTime={startTime}
            onError={(err) => setError({ ...error, end: err })}
            slotProps={{
              textField: {
                helperText: getErrorMessage(error.end),
                sx: {
                  width: 1,
                  borderRadius: 2,
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.common.white,
                  },
                },
              },
            }}
            slots={{
              openPickerButton: endTime
                ? () => (
                    <>
                      <IconButton
                        onClick={() => {
                          setEndTime(undefined);
                          onChange(null);
                        }}
                        edge="end"
                        ariaLabel={t("Clear")}
                      >
                        <ClearIcon />
                      </IconButton>
                    </>
                  )
                : undefined,
            }}
          />
        </Box>

        <Typography variant="body1" color="text.primary">
          {t("2. Choose an available location/device")}
        </Typography>
        {searchingKiosks ? (
          <CardSkeleton height={50} />
        ) : !availableKiosks || !startTime || !endTime ? (
          <Typography variant="body2">
            {t("Please specify a time to see available locations/devices")}
          </Typography>
        ) : availableKiosks.length === 0 ? (
          <Alert severity="error">
            {t("There are no locations or devices available during this time.")}
          </Alert>
        ) : (
          <Box display="flex" gap={1} flexWrap="wrap" pb={2}>
            {availableKiosks.map((kiosk) => (
              <SelectableKiosk
                key={kiosk.id}
                kiosk={kiosk}
                selected={kiosk.id === value?.kiosk.id}
                onSelect={(value) => {
                  if (!value) onChange(null);
                  else
                    onChange({
                      availability: {
                        interval: {
                          startAt: getCombinedDatetime(selectedDate, startTime),
                          endAt: getCombinedDatetime(selectedDate, endTime),
                        },
                        availableKiosks: [],
                        conflict: false,
                      },
                      kiosk: value,
                    });
                }}
              />
            ))}
          </Box>
        )}
        {value && value?.kiosk.capacity < meetingGroupSize && (
          <Alert severity="warning">
            {t("{{kioskName}} has a maximum capacity of {{count}}.", {
              kioskName: value.kiosk.name,
              count: value.kiosk.capacity,
            })}
          </Alert>
        )}
      </Stack>
    </Stack>
  );
}
