import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
  TextInput,
} from "@ameelio/ui";
import { Stack, ToggleButton, Typography } from "@mui/material";
import { isRequiredLength, isRequiredString } from "@src/lib/validate";
import i18n from "i18next";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (payload: { tag: string; description: string }) => void;
  loading: boolean;
  artifactType: "recording" | "screenshot";
}

enum Tags {
  MissingVideo = "missing-video",
  MissingAudio = "missing-audio",
  Other = "other",
  NoScreenshots = "no-screenshots",
}

const tagsByArtifactType = {
  recording: [Tags.MissingVideo, Tags.MissingAudio, Tags.Other],
  screenshot: [Tags.NoScreenshots, Tags.Other],
};

function getTagLabel(tag: Tags): string {
  switch (tag) {
    case Tags.MissingVideo:
      return i18n.t("Missing video");
    case Tags.MissingAudio:
      return i18n.t("Missing audio");
    case Tags.Other:
      return i18n.t("Other");
    case Tags.NoScreenshots:
      return i18n.t("No screenshots");
  }
}

const toggleTag = (tagToToggle: Tags, list: Tags[]) => {
  if (list.includes(tagToToggle)) {
    return list.filter((tag) => tag !== tagToToggle);
  }
  return [...list, tagToToggle];
};

type FormData = {
  tags: Tags[];
  detail: string;
};

export default function ReportArtifactProblemModal({
  visible,
  onCancel,
  onSubmit,
  loading,
  artifactType,
}: Props) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    watch,
    reset: resetForm,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      tags: [],
      detail: "",
    },
  });

  const tags = watch("tags");

  useEffect(() => {
    if (!visible && resetForm) {
      resetForm();
    }
  }, [visible, resetForm]);

  return visible ? (
    <Dialog onClose={onCancel} title={t("Report problem")}>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            {artifactType === "recording"
              ? t(
                  "Please note that the original file is intact, but occassionally problems occur when sharing it. We are here to help troubleshoot and get you the information you need."
                )
              : t(
                  "We are here to help troubleshoot and get you the information you need."
                )}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {t("What went wrong? (Select all that apply)")}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Controller
              control={control}
              name="tags"
              rules={isRequiredLength(t("Please select at least one option."))}
              render={({ field: { onChange, value } }) => (
                <>
                  {tagsByArtifactType[artifactType].map((tag) => (
                    <ToggleButton
                      value={tag}
                      selected={value.includes(tag)}
                      onChange={() => {
                        onChange(toggleTag(tag, value));
                      }}
                      sx={{
                        borderColor: value.includes(tag)
                          ? "primary.light"
                          : "divider",
                      }}
                    >
                      {getTagLabel(tag)}
                    </ToggleButton>
                  ))}
                </>
              )}
            />
          </Stack>
          {tags.includes(Tags.Other) ? (
            <TextInput
              control={control}
              name="detail"
              fullWidth
              label={t("Details")}
              multiline
              rows={2}
              placeholder={t("Provide as much detail as you can...")}
              rules={isRequiredString(t("Please provide details."))}
            />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack spacing={1} direction="row">
          <Button variant="outlined" key="cancel" onClick={onCancel}>
            {t("Cancel")}
          </Button>
          <SubmitButton
            key="submit"
            submitting={loading}
            disabled={!isValid || isSubmitting}
            disabledReason={t(
              "Please select at least one option, or describe the details of the problem."
            )}
            onClick={handleSubmit((data) => {
              onSubmit({
                tag: data.tags.map((tag) => tag.toString()).join(", "),
                description: data.detail.trim(),
              });
            })}
          >
            {t("Send")}
          </SubmitButton>
        </Stack>
      </DialogActions>
    </Dialog>
  ) : null;
}
