import { Box, Typography } from "@mui/material";
import { TranscriptStatus } from "@src/api/graphql";
import Result from "@src/lib/Result";
import ScreenSection from "@src/lib/ScreenSection";
import { useTranslation } from "react-i18next";
import { GetCallWithRecordingQuery } from "../GetCallWithRecording.generated";

type Props = {
  meeting: GetCallWithRecordingQuery["meeting"];
  transcriptStatus: TranscriptStatus;
  transcriptContent: string;
};

export default function TranscriptModule({
  meeting,
  transcriptStatus,
  transcriptContent,
}: Props) {
  const { t } = useTranslation();
  const errorTitle = (() => {
    switch (transcriptStatus) {
      case TranscriptStatus.NoTranscript:
        return t("No transcript available");
      case TranscriptStatus.Pending:
        return t("In progress");
      case TranscriptStatus.Expired:
        return t("Expired link");
    }
    return t("Unavailable");
  })();

  const errorSubtitle = (() => {
    switch (transcriptStatus) {
      case TranscriptStatus.NoTranscript:
        return t("");
      case TranscriptStatus.Pending:
        return t(
          "This transcript is still processing. Please check back later."
        );
      case TranscriptStatus.Expired:
        return t(
          "All transcripts permanently expire {{count}} days after the meeting start time.",
          {
            count: meeting.facility.system.recordingRetention,
          }
        );
    }
    return t("This transcript is not available.");
  })();
  return (
    <ScreenSection
      title={t("Call transcript")}
      sx={{ mb: 2 }}
      titlePosition="inner"
    >
      {transcriptStatus === TranscriptStatus.Available && (
        <>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ fontWeight: 500 }}
          >
            {t(
              "Note: Transcripts are created automatically and have not been reviewed for accuracy. They do not include non-verbal sounds, and may contain inaccuracies in words, time stamps, and speaker assignment."
            )}
          </Typography>
          <Box maxHeight={500} overflow="auto" pt={2}>
            {transcriptContent.split("\n\n").map((segment, index) => (
              <Box p={1}>
                <Typography
                  key={`segment_${index}`}
                  variant="body1"
                  color="text.primary"
                >
                  {segment}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
      {transcriptStatus !== TranscriptStatus.Available && (
        <Result variant="404" title={errorTitle} subTitle={errorSubtitle} />
      )}
    </ScreenSection>
  );
}
