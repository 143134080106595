import { Link } from "@mui/material";
import { useAppNavigate } from "@src/lib/useAppNavigate";

interface Props {
  organization: {
    id: string;
    name: string;
  };
}

const OrganizationCell = ({ organization }: Props) => {
  const navigate = useAppNavigate();
  return (
    <Link onClick={() => navigate(`/organizations/${organization.id}`)}>
      {organization.name}
    </Link>
  );
};

export default OrganizationCell;
