import { Button } from "@ameelio/ui";
import { ButtonProps } from "@mui/material";
import { Entitlement, MeetingStatus } from "@src/api/graphql";
import ContextMenuItem from "@src/lib/ContextMenuItem";
import useBoolean from "@src/lib/useBoolean";
import useEntitlement from "@src/lib/useEntitlement";
import { useTranslation } from "react-i18next";
import DeclineMeetingModal, { MeetingToReject } from "./DeclineMeetingModal";

type Props = Omit<ButtonProps, "variant" | "color" | "onClick"> & {
  meeting: MeetingToReject;
  displayAsMenuItem?: boolean;
};

export default function DeclineMeetingButton({
  meeting,
  displayAsMenuItem,
  ...rest
}: Props) {
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings
  );
  const { t } = useTranslation();
  const rejecting = useBoolean();

  return (
    <>
      {rejecting.current && (
        <DeclineMeetingModal meeting={meeting} onClose={rejecting.disable} />
      )}
      {displayAsMenuItem ? (
        <ContextMenuItem color="error" onClick={rejecting.enable}>
          {meeting.status === MeetingStatus.PendingApproval
            ? t("Decline meeting")
            : t("Cancel meeting")}
        </ContextMenuItem>
      ) : (
        <Button
          variant="outlined"
          color="error"
          onClick={rejecting.enable}
          disabled={!canManageVisitorsAndMeetings}
          disabledReason={t("You do not have permission to decline meetings.")}
          {...rest}
        >
          {meeting.status === MeetingStatus.PendingApproval
            ? t("Decline")
            : t("Cancel")}
        </Button>
      )}
    </>
  );
}
