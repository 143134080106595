import FacilityMenu from "@src/lib/FacilityMenu";
import MenuLayout from "@src/lib/MenuLayout";
import React from "react";
import { MenuTab } from "./routes";

type Props = {
  children: React.ReactNode;
  selectedTab: MenuTab;
};

export default function FacilityLayout({ selectedTab, children }: Props) {
  return (
    <MenuLayout menu={<FacilityMenu selectedTab={selectedTab} />}>
      {children}
    </MenuLayout>
  );
}
