import { Stack } from "@mui/material";
import { formatDate } from "@src/lib/Time";
import { TypographyText } from "./typography";

interface Props {
  message: {
    callId: string;
    senderType: "Inmate" | "Visitor" | "Staff";
    senderName: string;
    contents: string;
    createdAt: number;
  };
  className?: string;
}

const MessageDisplay: React.FC<Props> = ({ message, className }) => {
  return (
    <Stack
      spacing={1}
      alignItems={message.senderType === "Inmate" ? "flex-end" : "flex-start"}
      sx={{ width: "100%" }}
      className={className}
    >
      <Stack direction="row" spacing={1}>
        <TypographyText sx={{ fontWeight: 600 }}>
          {message.senderName}
        </TypographyText>
        <TypographyText type="secondary">
          {formatDate(new Date(message.createdAt), "time")}
        </TypographyText>
      </Stack>
      <TypographyText>{message.contents}</TypographyText>
    </Stack>
  );
};

export default MessageDisplay;
